.header-nav {
    display: grid;
    grid-template-columns: 1fr 350px;
    min-height: 71px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 0 15px;
}

.header__navbar-nav {
    margin: 0;
    width: 100%;
}

@media screen and (max-width: 1174px) {
    .header-nav {
        padding: 0 15px 65px 15px;
    }

    .header-nav__info {
        top: 65px;
        position: absolute;
    }
}

@media screen and (max-width: 991px) {
    .header-nav {
        grid-template-columns: 1fr 150px;
        padding: 15px 15px 60px 15px;
    }

    .header-nav__info {
        top: 65px;
        position: absolute;
        width: calc(100vw - 30px);
    }
}

@media screen and (max-width: 350px) {
    .header-nav__info {
        display: none !important;
    }

    .header-nav {
        grid-template-columns: 1fr 150px;
        padding: 15px 15px 15px 15px;
    }
}

.header-nav__content {
    flex-wrap: wrap;
    width: 100%;
}

.header__navbar-brand {
    height: 100%;
}

.header__navbar-text {
    margin: 0;
    color: #777;
}

.header-nav__info {
    flex-wrap: wrap;
    gap: 20px;
}

.header__social_media {
    display: flex;
    flex-direction: column;
}

.header__social_media__icons {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.header__social_media__icon {
    width: 20px;
    height: 20px;
}

.header__digital-tax-free {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-decoration: none;
}

.header__digital-tax-free img {
    height: 30px;
    width: auto;
}

.header__digital-tax-free * {
    text-decoration: none;
    color: #777;
}
