.footer__credit-cards {
    margin-bottom: 5px;
}

.footer__credit-cards__item {
    height: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

.footer__credit-cards__item svg {
    height: 100%;
    width: auto;
}

.gpay-icon {
    border-radius: 25%/50%;
    border: 1px solid #ddd
}

.apple-pay-icon {
    border: 1px solid #ddd 
}

.visa-card-icon {
    height: 20px;
}